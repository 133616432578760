export default function scrollToList(mobileOnly = true) {
  if (mobileOnly && window.innerWidth > 1024) {
    // console.log(window.innderWidth);
    return;
  }
  const $wrapper = document.getElementById('swpf-list');
  if (!$wrapper) return;
  const elementPosition = $wrapper.offsetTop;
  window.scrollTo({ top: elementPosition, behavior: 'smooth' });
}
